import React, { FC } from 'react'
import styles from './BodyHeader.module.scss'
import Rates from './Rates'
import Link from 'next/link'
import HeaderLogoSvg from '../../public/icons/logo-header.svg'
import Forum from '../../public/icons/forum-icon.svg'
import { LayoutAuth } from './LayoutAuth/LayoutAuth'
import cn from 'classnames'
import TgLinkCounter from './TgLinkCounter'
import { useRouter } from 'next/router'
import { SPECIAL_PAGES } from '../../lib/constants'

// import GigaChat from '../common/GigaChat'

interface IPropsBody {
  posY: number
  isWhite?: boolean
  tgUsersCounter: number
}

export const BodyHeader: FC<IPropsBody> = ({
  posY,
  isWhite,
  tgUsersCounter,
}) => {
  const router = useRouter()
  const isSpecialPage = SPECIAL_PAGES.some((page) =>
    router.asPath.replace(/^\//, '').includes(page)
  )

  return (
    <div className={cn(styles.middle, posY > 80 && styles.withoutLine)}>
      <div className={styles.rates}>
        <Rates />
      </div>
      <Link
        className={cn(styles.logo, isWhite && styles['logo--white'])}
        href="/"
        aria-label="На главную"
      >
        <HeaderLogoSvg />
      </Link>
      <div
        className={cn(
          styles.buttons,
          tgUsersCounter > 0 && styles['buttons--no-flex']
        )}
      >
        {/* <GigaChat
          classNames={{
            container: styles.chat,
            button: isWhite && styles.whiteGigaChat,
          }}
        /> */}

        {tgUsersCounter > 0 ? (
          <TgLinkCounter
            isLight={isSpecialPage}
            tgUsersCounter={tgUsersCounter}
            posY={posY}
          />
        ) : (
          <>
            <Link
              href="https://t.me/forum_code"
              className={cn(styles.forum, isWhite && styles['forum--white'])}
              target="_blank"
            >
              Форум
              <Forum />
            </Link>
            <LayoutAuth
              className={cn(styles.auth, isWhite && styles['auth--white'])}
            />
          </>
        )}
      </div>
    </div>
  )
}
