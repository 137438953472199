import { FC, SVGProps } from 'react'

interface INavItemMobile {
  href?: string
  slug?: string
  name: string
  icon?: FC<SVGProps<SVGSVGElement>>
}

interface INavItem extends INavItemMobile {
  total?: number
  subcat?: INavItem[]
}

type File = {
  url: string
  id: string
}

type Subtitles<T> = {
  startTime: number
  endTime: number
  element: T
  index: number
}

enum ThemeEnum {
  system = '',
  dark = 'dark',
  light = 'light',
}

export type { INavItemMobile, INavItem, File, Subtitles }
export { ThemeEnum }
