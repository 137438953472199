import React, { FC } from 'react'
import styles from './TgLinkCounter.module.scss'
import TgSvg from './icons/tg-bg.svg'
import { declOfNum } from '../../utils/declOfNum'
import cn from 'classnames'

const TgLinkCounter: FC<{
  tgUsersCounter: number
  posY?: number
  hideCounter?: boolean
  isLight?: boolean
}> = ({ tgUsersCounter, posY = 0, hideCounter, isLight }) => {
  return (
    <a
      href="https://bit.ly/d_code"
      target="_blank"
      className={cn(
        styles['counter-wrapper'],
        isLight && styles['counter-wrapper--light']
      )}
    >
      <TgSvg className={styles['counter-icon']} />
      <div
        className={cn(
          styles['counter-text'],
          posY > 80 && styles['counter-text--active'],
          hideCounter && styles['counter-text--auto-height']
        )}
      >
        <p className={styles['counter-title']}>Читайте в&nbsp;Telegram</p>
        {!hideCounter && (
          <p className={styles['counter-subtitle']}>
            {tgUsersCounter.toLocaleString('ru')}{' '}
            {declOfNum(tgUsersCounter, [
              'подписчик',
              'подписчика',
              'подписчиков',
            ])}
          </p>
        )}
      </div>
    </a>
  )
}

export default TgLinkCounter
